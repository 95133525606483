<template>
  <v-container class="mt-4 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title class="text-subtitle-1">
            Permisos por area
            <v-spacer></v-spacer>
            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( modalidades  , 'MODALIDADES_ERP')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

          </v-card-title>
          <v-card-text>

            <v-row justify="end">
              <v-col cols="12" md="6">
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-data-table
              :headers="headers"
              :items="permisos"
              sort-by="calories"
              class="elevation-0"
              :search="search"
            >

              <template v-slot:item.autorizo_rh="{ item }">
                <v-chip small v-if="!item.autorizo_rh && !item.autorizo_jefe">SIN REVISION</v-chip>
                <v-chip small v-else-if="item.autorizo_rh == 1" color="green" dark>ACEPTADO</v-chip>
                <v-chip small v-else-if="item.autorizo_rh == 2" color="red" dark>NO ACEPTADO</v-chip>
                <v-chip small v-else-if="item.autorizo_rh && !item.autorizo_jefe" color="orange" dark>EN REVISION</v-chip>
                <v-chip small v-else-if="!item.autorizo_rh && item.autorizo_jefe" color="orange" dark>EN REVISION</v-chip>
              </template>

              <template v-slot:item.autorizo_jefe="{ item }">
                <v-chip small v-if="!item.autorizo_jefe && !item.autorizo_rh">SIN REVISION</v-chip>
                <v-chip small v-else-if="item.autorizo_jefe == 1" color="green" dark>ACEPTADO</v-chip>
                <v-chip small v-else-if="item.autorizo_jefe == 2" color="red" dark>NO ACEPTADO</v-chip>
                <v-chip small v-else-if="item.autorizo_rh && !item.autorizo_jefe" color="orange" dark>EN REVISION</v-chip>
                <v-chip small v-else-if="!item.autorizo_rh && item.autorizo_jefe" color="orange" dark>EN REVISION</v-chip>
                <v-chip small v-else-if="item.autorizo_jefe && item.autorizo_rh" color="green" dark>ACEPTADO</v-chip>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-btn 
                  color="primary"
                  @click="editItem(item)"
                  tile
                  dark
                  x-small
                >
                  <v-icon
                    small
                  >
                    mdi-magnify
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:no-data>
                <v-btn
                  color="primary"
                  @click="initialize"
                >
                  Reset
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Folio: {{ editedItem.idsolicitud_permisos }}</span>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            dark
            rounded
            small
            icon
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="black--text" v-if="editedIndex >= 0">
          <span><b>Solicitante: </b> {{ editedItem.solicitante }}</span>
          <br/>
          <span><b>Rango días:  </b> {{ editedItem.fecha_inicio }} a {{ editedItem.fecha_final }} </span>
          <br/>
          <span><b>Motivo:</b> {{ editedItem.motivo }}</span>
          <br/>
          <br/>
          <br/>

          <v-row>
            <v-col cols="12" md="6">
              <span><b>Jefe Directo:</b> {{ editedItem.jefe }}</span>
              <br/>
              <v-chip v-if="editedItem.autorizo_jefe == 1"  color="green" dark small>Autorizó</v-chip>
              <v-chip v-else-if="editedItem.autorizo_jefe == 2" color="red"   dark small>Rechazó</v-chip>
              <span v-else>
                <v-radio-group
                  v-model="autorizar"
                  row
                >
                  <v-radio
                    label="Autorizar"
                    :value="1"
                  ></v-radio>
                  <v-radio
                    label="Rechazar"
                    :value="2"
                  ></v-radio>
                </v-radio-group>
              </span>
              <v-textarea
                v-if="autorizar == 2"
                filled
                hide-details
                dense
                label="Motivo del rechazo"
                v-model="nota_rechazo"
              > 
              </v-textarea>
              <br/>
              <span v-if="editedItem.motivo_rechazo_jefe"> {{ editedItem.motivo_rechazo_jefe }}</span>
            </v-col>

            <v-col cols="12" md="6">
              <span><b>RH:</b> {{ editedItem.rh }}</span>
              <br/>
              <v-chip 
                v-if="editedItem.autorizo_rh == 1"  
                color="green" 
                dark 
                small
              >
                Autorizó
              </v-chip>
              
              <v-chip 
                v-else-if="editedItem.autorizo_rh == 2" 
                color="red"   
                dark 
                small
              >
                Rechazó
              </v-chip>
              
              <v-chip 
                v-else 
                color="grey"   
                dark 
                small
              >
                Pendiente
              </v-chip>
              <br/>
              <span v-if="editedItem.motivo_rechazo_rh"> {{ editedItem.motivo_rechazo_rh }}</span>
            </v-col>
          </v-row>
          <v-row v-if="editedItem.archivo_adjunto != 'null'">
            <v-col cols="12">
              <v-img :src="url + editedItem.archivo_adjunto"/>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            dark
            tile
            small
            @click="close"
          >
            <v-icon left small>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="![1,2].includes(editedItem.autorizo_rh)"
            color="green"
            dark
            tile
            small
            @click="save"
          >
            <v-icon left small>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'
  import funcionesExcel  from '@/mixins/funcionesExcel'
  import leerImagenes    from '@/mixins/leerImagenes'


  export default {

    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel, leerImagenes ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'ID'              , value: 'idsolicitud_permisos', sortable: false },
        { text: 'Solicitante'     , value: 'solicitante'                           },
        { text: 'Fecha'           , value: 'fecha_creacion'                        },
        { text: 'Autorizado RH'   , value: 'autorizo_rh'                           },
        { text: 'Autorizado JEFE' , value: 'autorizo_jefe'                         },
        { text: 'Actions'         , value: 'actions', sortable: false              },
      ],

      search:'',
      permisos: [],
      editedIndex: -1,
      editedItem: {
        idsolicitud_permisos: '',
        id_usuario: 0,
        idareas_ticket: 0,
        id_jefe_directo: 0,
        id_rh: null,
        autorizo_jefe: null,
        autorizo_rh: null,
        fecha_inicio:null,
        fecha_final: null,
        motivo: '',
        archivo_adjunto: null,
        motivo_rechazo_jefe:'',
        motivo_rechazo_rh:''
      },
      defaultItem: {
        idsolicitud_permisos: '',
        id_usuario: 0,
        idareas_ticket: 0,
        id_jefe_directo: 0,
        id_rh: null,
        autorizo_jefe: null,
        autorizo_rh: null,
        fecha_inicio:null,
        fecha_final: null,
        motivo: '',
        archivo_adjunto: null,
        motivo_rechazo_jefe:'',
        motivo_rechazo_rh:''
      },

      file: '',
      dragging: false,
      vistaPrevia: null,
      autorizar:null,
      nota_rechazo:'',
      url:''



    }),

    computed: {
      ...mapGetters('login', ['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.url = axios.defaults.baseURL + 'justificantes/'

      this.initialize()
    },

    methods: {
      initialize () {
        this.permisos = []
        this.cargar = true
        return this.$http.get('permisos.area/' + this.getdatosUsuario.iderp ).then(response=>{
          this.permisos = response.data
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.permisos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.permisos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.permisos.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.autorizar = null
        this.nota_rechazo = ''
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        // Guardar imagen primero y retornar lo demás
        this.cargar = true
        let formData = new FormData();
        //se crea el objeto y se le agrega como un apendice el archivo
        formData.append('idsolicitud_permisos'  ,this.editedItem.idsolicitud_permisos)
        formData.append('id_jefe_directo'       ,this.getdatosUsuario.iderp)
        formData.append('autorizo_jefe'         ,this.autorizar)
        formData.append('motivo_rechazo_jefe'   ,this.nota_rechazo)

        return this.$http.post('permisos.aceptar.jefe', formData ).then(response=>{
          this.file = null
          this.initialize()
          this.validarSuccess( response.data.message )
          this.cargar = false
          this.close()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },
    },
  }
</script>
<style scoped>
  .dropZone {
    width: 80%;
    height: 150px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px solid #D8D8D8;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #D8D8D8;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 80%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D8D8D8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }
</style>